export const errorMessagesIndicatingOldAppVersion = [
  "Failed to fetch dynamically imported module",
  "Unable to preload CSS",
  "'text/html' is not a valid JavaScript MIME type",
  "error loading dynamically imported module",
  "Importing a module script failed",
  "Load Failed",
];

export const messagesToIgnore = [
  "Cannot read properties of null (reading 'disconnect')", // vue-popper error
  "null is not an object (evaluating 'n.disconnect')", // vue-popper error
  "Failed to execute 'observe' on 'MutationObserver'", // vue-popper error
  "Can't find variable: TouchEvent", // Formkit error
  ...errorMessagesIndicatingOldAppVersion,
];
